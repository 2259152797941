import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import '../models/configurationModal';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {
  enable_WLS_SPLIT = new BehaviorSubject<boolean>(false) ;
  appURL = environment.appURL;
  constructor(private http: HttpClient) { }

  getConfigMetaData(accountId: number) {
    return this.http.get<getConfigMetaData>(`${this.appURL}/accounts/${accountId}/configurations/metadata`);
  }
  createConfiguration(body: ConfigurationRequest, accountId: number) {
    return (this.http.post<CreateConfigurationResponse>(`${this.appURL}/accounts/${accountId}/configurations`, body));
  }
  getConfigurationsById(accountId: string, configId: string) {
    return this.http.get<getConfigData>(`${this.appURL}/accounts/${accountId}/configurations/${configId}`);
  }
  getConfigUpdatedDataById(accountId: number, configId: number) {
    let obMeta = this.http.get<getConfigMetaData>(`${this.appURL}/accounts/${accountId}/configurations/metadata`)
    let obSelected = this.http.get<getConfigData>(`${this.appURL}/accounts/${accountId}/configurations/${configId}`);
    return forkJoin([obMeta, obSelected]);
  }

  updateConfiguration(body: ConfigurationRequest, accountId: number, configId: number) {
    return (this.http.put<CreateConfigurationResponse>(`${this.appURL}/accounts/${accountId}/configurations/${configId}`, body));
  }
}
