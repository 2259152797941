import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { UserService } from 'src/app/services/user.service';
import { CONFIGURATION_UPDATE, CONFIGURATION_ALL } from '../../constants';

@Component({
  selector: 'app-view-configuration',
  templateUrl: './view-configuration.component.html',
  styleUrls: ['./view-configuration.component.scss']
})
export class ViewConfigurationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  accountId: string = '';
  configId: string = '';
  accountName = '';
  configData!: GetConfigurationResponse;
  public watchList_sanc = {} as ScreeningLevel;
  public watchLists = {} as ScreeningLevel;
  public sanctions = {} as ScreeningLevel;
  public political = {} as ScreeningLevel;
  public adverseMedia = {} as ScreeningLevel;
  public is_WLS_Enable = false;
  ifUser: boolean = false;
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/config/account/list' },
    { label: 'View Configuration Details' }];
  showAudit = false;
  filterObject = {
    type: "Configuration",
    id: ""
  };
  editConfigPermissions = [CONFIGURATION_UPDATE, CONFIGURATION_ALL];
  public allowEmails = ['panchala@dnb.com', 'londheh@dnb.com', 'ballonet@dnb.com'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigDataService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user => {
      if (this.allowEmails.indexOf(user.emailAddress) > -1) {
          this.ifUser = true;
      }
    });
    this.configService.enable_WLS_SPLIT.subscribe(val =>{this.is_WLS_Enable = val;});
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getConfigurationById(params['accountId'], params['configId']);
      });
  }

  getConfigurationById(accountId: string, configId: string) {
    this.accountId = accountId;
    this.configId = configId;
    this.configService.getConfigurationsById(accountId, configId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          this.configData = result.responseData.configurationResponse;
          this.filterObject.id = this.configId;
          this.configData.screeningLevels.map((screeningLevel: ScreeningLevel) => {
            switch (screeningLevel.code) {
              case '1':
                this.watchList_sanc = screeningLevel;
                break;
              case '2':
                this.political = screeningLevel;
                break;
              case '3':
                this.adverseMedia = screeningLevel;
                break;
              case '4':
                this.watchLists = screeningLevel;
                break;
              case '5':
                this.sanctions = screeningLevel;
              break;
            }
          });
        }
      });
  }

  public changed(evt: any) {    
    this.configService.enable_WLS_SPLIT.next(evt.target.checked);
    }
    

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
