<p-breadcrumb [model]="items"></p-breadcrumb>
<div class="topBarWrapper">
  <h1 class="heading-text">Configuration Profile for <span>{{configData?.profileName}} ({{accountId}})</span></h1>
  <ng-container *hasPermission="editConfigPermissions">
    <a class="button  editAction" *ngIf="configData?.accountStatus"
      [routerLink]="['/config/configuration/account',accountId,'config',configId,'edit']">Edit</a>
  </ng-container>
  <span *ngIf="configData?.accountStatus === false" class="notification">Account is Inactive</span>
</div>
<div class="setupDetailWrapper">
  <div class="configureField">
    <div class="setup-section">
      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Profile Name:</span>
        <div class="value">
          {{configData?.profileName}}
        </div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Description / Note: </span>
        <div class="control-wrapper descriptionNote">
          {{configData?.descriptionNote || 'NA'}}
        </div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Monitoring Enabled:</span>
        <div class="value">
          {{configData?.monitoringEnabled ? 'Yes' : 'No'}}
        </div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Default Config:</span>
        <div class="value">
          {{configData?.defaultConfig ? 'Yes' : 'No'}}
        </div>
      </div>
    </div>
    <div class="additionalField profileStatus">
      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">RPS Config ID:</span>
        <div class="value">
          {{configData?.rpsConfigId}}
        </div>
      </div>
      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Status:</span>
        <div class="value configStatus {{configData?.configurationStatus}}">
          {{configData?.configurationStatus}}
        </div>
      </div>
      <div class="fieldWrapper" *ngIf="ifUser">
        <span class="col-sm-4 col-form-label">WLS Split Enable:</span>
        <div class="value configStatus">
          <input type="checkbox" [checked]="is_WLS_Enable" (change)="changed($event)" id="no"/>
        </div>
      </div>
      
    </div>
  </div>
  <div class="tabLayout-wrapper" *ngIf="watchList_sanc.code || watchLists.code">
    <p-tabView>
      <p-tabPanel header="Watchlists & Sanctions" *ngIf="watchList_sanc.code && is_WLS_Enable === false">
        <div>
          <div class="setup-section">
            <div class="fieldWrapper">
              <span class="col-sm-4 col-form-label">Time Frame for Searches: </span>
              <div class="value">
                {{watchList_sanc.selectedTimeFrame?.description}}
              </div>
            </div>
            <div class="fieldWrapper" *ngIf="watchList_sanc.customListEnabled">
              <span class="col-sm-4 col-form-label">Custome List ID: </span>
              <div class="value">
                {{watchList_sanc.customListId || 'NA'}}
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Product Categories: </span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of watchList_sanc.categories;">
                    <div class="parent">
                      <span class="pi" [ngClass]="category.show ? 'pi-angle-down' : 'pi-angle-right'"
                        (click)="category.show=!category.show"></span>
                      {{category.code}}
                    </div>
                    <div *ngIf="category.show">
                      <div class="options" *ngFor="let option of category.options">
                        <input type="checkbox" class="custom" [checked]="option.selected" disabled>
                        {{option.description}}
                      </div>
                    </div>
                  </div>
                </div><!-- categoryWrapper -->
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Sub Categories: </span>
              <div class="value">
                <div class="subCategoryWrapper">
                  <div class="fieldWrapper" *ngFor="let choice of watchList_sanc.subProducts; let i=index">
                    <input type="checkbox" class="custom" [checked]="choice.selected" disabled>
                    <span>
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Watchlists" *ngIf="watchLists.code  && is_WLS_Enable === true">
        <div>
          <div class="setup-section">
            <div class="fieldWrapper">
              <span class="col-sm-4 col-form-label">Time Frame for Searches: </span>
              <div class="value">
                {{watchLists.selectedTimeFrame?.description}}
              </div>
            </div>
            <div class="fieldWrapper" *ngIf="watchLists.customListEnabled">
              <span class="col-sm-4 col-form-label">Custome List ID: </span>
              <div class="value">
                {{watchLists.customListId || 'NA'}}
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Product Categories: </span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of watchLists.categories;">
                    <div class="parent">
                      <span class="pi" [ngClass]="category.show ? 'pi-angle-down' : 'pi-angle-right'"
                        (click)="category.show=!category.show"></span>
                      {{category.code}}
                    </div>
                    <div *ngIf="category.show">
                      <div class="options" *ngFor="let option of category.options">
                        <input type="checkbox" class="custom" [checked]="option.selected" disabled>
                        {{option.description}}
                      </div>
                    </div>
                  </div>
                </div><!-- categoryWrapper -->
              </div>
            </div>
            <!-- <div class="form-group categoryControl">
              <span class="col-form-label">Sub Categories: </span>
              <div class="value">
                <div class="subCategoryWrapper">
                  <div class="fieldWrapper" *ngFor="let choice of watchLists.subProducts; let i=index">
                    <input type="checkbox" class="custom" [checked]="choice.selected" disabled>
                    <span>
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Sanctions" *ngIf="sanctions.code && is_WLS_Enable === true">
        <div>
          <div class="setup-section">
            <div class="fieldWrapper">
              <span class="col-sm-4 col-form-label">Time Frame for Searches: </span>
              <div class="value">
                {{sanctions.selectedTimeFrame?.description}}
              </div>
            </div>
            <div class="fieldWrapper" *ngIf="sanctions.customListEnabled">
              <span class="col-sm-4 col-form-label">Custome List ID: </span>
              <div class="value">
                {{sanctions.customListId || 'NA'}}
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Product Categories: </span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of sanctions.categories;">
                    <div class="parent">
                      <span class="pi" [ngClass]="category.show ? 'pi-angle-down' : 'pi-angle-right'"
                        (click)="category.show=!category.show"></span>
                      {{category.code}}
                    </div>
                    <div *ngIf="category.show">
                      <div class="options" *ngFor="let option of category.options">
                        <input type="checkbox" class="custom" [checked]="option.selected" disabled>
                        {{option.description}}
                      </div>
                    </div>
                  </div>
                </div><!-- categoryWrapper -->
              </div>
            </div>
            <!-- <div class="form-group categoryControl">
              <span class="col-form-label">Sub Categories: </span>
              <div class="value">
                <div class="subCategoryWrapper">
                  <div class="fieldWrapper" *ngFor="let choice of sanctions.subProducts; let i=index">
                    <input type="checkbox" class="custom" [checked]="choice.selected" disabled>
                    <span>
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Political Affiliations" *ngIf="political?.code">
        <div>
          <div class="setup-section">
            <div class="form-group categoryControl">
              <span class="col-form-label">PEPDesk Categories: </span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of political.categories">
                    <div class="parent">
                      <input type="checkbox" class="custom" [checked]="category.selected" disabled>
                      {{category.description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Sub Categories: </span>
              <div class="value">
                <div class="subCategoryWrapper">
                  <div class="fieldWrapper" *ngFor="let choice of political.subProducts; let i=index">
                    <input type="checkbox" class="custom" [checked]="choice.selected" disabled>
                    <span>
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Adverse Media" *ngIf="adverseMedia?.code">
        <div>
          <div class="setup-section">
            <div class="fieldWrapper">
              <span class="col-sm-4 col-form-label">Time Frame for Searches: </span>
              <div class="value">
                {{adverseMedia.selectedTimeFrame?.description}}
              </div>
            </div>
            <div class="form-group categoryControl">
              <span class="col-form-label">Geographic Coverage:</span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of adverseMedia.categories;">
                    <div class="parent">
                      <span class="pi" [ngClass]="category.show ? 'pi-angle-down' : 'pi-angle-right'"
                        (click)="category.show=!category.show"></span>
                      {{category.description}}
                    </div>
                    <div *ngIf="category.show">
                      <div class="options" *ngFor="let option of category.options">
                        <input type="checkbox" class="custom" [checked]="option.selected" disabled>
                        {{option.description}}
                      </div>
                    </div>
                  </div>
                </div><!-- categoryWrapper -->
              </div>
            </div>

            <div class="form-group categoryControl">
              <span class="col-form-label">Event Types:</span>
              <div class="value">
                <div class="categoryWrapper">
                  <div class="category" *ngFor="let category of adverseMedia.eventTypes;">
                    <div class="parent">

                      <span class="pi" [ngClass]="category.show ? 'pi-angle-down' : 'pi-angle-right'"
                        (click)="category.show=!category.show"></span>
                      {{category.code}}
                    </div>
                    <div *ngIf="category.show">
                      <div class="options" *ngFor="let option of category.options">
                        <input type="checkbox" class="custom" [checked]="option.selected" disabled>
                        {{option.description}}
                      </div>
                    </div>
                  </div>
                </div><!-- categoryWrapper -->
              </div>
            </div>
            <div class="form-group categoryControl" *ngIf="adverseMedia?.subProducts?.length">
              <span class="col-form-label">Sub Categories: </span>
              <div class="value">
                <div class="subCategoryWrapper">
                  <div class="fieldWrapper" *ngFor="let choice of adverseMedia.subProducts; let i=index">
                    <input type="checkbox" class="custom" [checked]="choice.selected" disabled>
                    <span>
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
    <a routerLink="/" class="button submitButton">Back</a>
    <button class="button showHide" (click)="showAudit = !showAudit">{{ showAudit === true ? 'Hide' : 'Show' }} Audit Logs</button>
  </div>
  <div>
    <!-- <div class="topBarWrapper">
      <h2 class="heading-text">Audit Details</h2>
      <button class="button showHide" (click)="showAudit = !showAudit">Show/Hide</button>
    </div> -->
    <app-audit-trail [inputFilter]="filterObject" *ngIf="showAudit"></app-audit-trail>
  </div>
</div>
