import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { UserService } from 'src/app/services/user.service';
import * as XRegExp from 'xregexp';
import '../../models/configurationModal';

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss']
})
export class CreateConfigurationComponent implements OnInit, OnDestroy {
  WORDS_REGX = XRegExp('^[\\p{L}\'\\s]*$');
  isUpdating: boolean = false;
  public accountId = 0;
  public configId = 0;
  public configForm!: FormGroup;
  private unsubscribe$ = new Subject<void>();
  public accountName = "";
  public screeningLevels: ScreeningLevel[] = [];
  public watchLists_sanc = {} as screenLevelI;
  public watchLists = {} as screenLevelI;
  public sanctions = {} as screenLevelI;
  public political = {} as screenLevelI;
  public adverseMedia = {} as screenLevelAdverseMedia;
  public request!: ConfigurationRequest;
  public is_WLS_Enable = false;
  ifUser: boolean = false;
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/config/account/list' },
    { label: 'Create Configuration' }];
  public allowEmails = ['panchala@dnb.com', 'londheh@dnb.com', 'ballonet@dnb.com'];
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: ConfigDataService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user => {
      if (this.allowEmails.indexOf(user.emailAddress) > -1) {
          this.ifUser = true;
      }
    });
    this.configService.enable_WLS_SPLIT.subscribe(val =>{this.is_WLS_Enable = val;});
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.isUpdating = data['isUpdating'];
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.accountId = params['accountId'];
        this.configId = params['configId'];
        if (this.isUpdating)
          this.getUpdatedConfigByAccountId(this.accountId, this.configId);
        else
          this.getConfigByAccountId(this.accountId);
      });

    this.configForm = this.formBuilder.group({
      profileName: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.pattern(this.WORDS_REGX)]),
      descriptionNote: new FormControl(''),
      monitoringEnabled: new FormControl(''),
      defaultConfig: new FormControl(''),
      profileStatus: new FormControl('Draft'),
      watchlistAndSanctions: this.formBuilder.group({
        timeFrame: new FormControl('0'),
        pressRelease: new FormControl(''),
        categories: new FormControl([]),
        subProducts: new FormArray([]),
        customListEnabled: new FormControl(''),
        customListId: new FormControl(''),
      }),
      watchlists: this.formBuilder.group({
        timeFrame: new FormControl('0'),
        pressRelease: new FormControl(''),
        categories: new FormControl([]),
        subProducts: new FormArray([]),
        customListEnabled: new FormControl(''),
        customListId: new FormControl(''),
      }),
      sanctions: this.formBuilder.group({
        timeFrame: new FormControl('0'),
        pressRelease: new FormControl(''),
        categories: new FormControl([]),
        subProducts: new FormArray([]),
        customListEnabled: new FormControl(''),
        customListId: new FormControl(''),
      }),
      politicalAffiliations: this.formBuilder.group({
        categories: new FormControl([]),
        subProducts: new FormArray([]),
        customListEnabled: new FormControl(''),
      }),
      adverseMedia: this.formBuilder.group({
        timeFrame: new FormControl('0'),
        categories: new FormControl([]),
        eventTypes: new FormControl([]),
        subProducts: new FormArray([]),
        customListEnabled: new FormControl(''),
      })
    });
  }

  getConfigByAccountId(accountId: number) {
    this.configService.getConfigMetaData(accountId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          const data: ConfigurationData = result.responseData;
          this.accountName = data.accountName;
          this.screeningLevels = data.screeningLevels;
          const monitoringEnabled = data.monitoringEnabled ? 'true' : 'false';
          this.configForm.controls['monitoringEnabled'].patchValue(monitoringEnabled);
          const defaultConfig = data.defaultConfig ? 'true' : 'false';
          this.configForm.controls['defaultConfig'].patchValue(defaultConfig);
          this.handleScreeningLevels();
        }
      });
  }

  getUpdatedConfigByAccountId(accountId: number, configId: number) {
    this.configService.getConfigUpdatedDataById(accountId, configId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([configMetaData, configSelectedData]) => {
        this.accountName = configMetaData.responseData.accountName;
        this.setSelectedScreeningToDefault(configMetaData.responseData.screeningLevels, configSelectedData.responseData.configurationResponse.screeningLevels);
        if (configMetaData?.responseData) {
          const data: GetConfigurationResponse = configSelectedData.responseData.configurationResponse;
          this.handleScreeningLevels();
          this.configForm.controls['profileName'].patchValue(data.profileName);
          this.configForm.controls['descriptionNote'].patchValue(data.descriptionNote);
          const monitoringEnabled = data.monitoringEnabled ? 'true' : 'false';
          this.configForm.controls['monitoringEnabled'].patchValue(monitoringEnabled);
          const defaultConfig = data.defaultConfig ? 'true' : 'false';
          this.configForm.controls['defaultConfig'].patchValue(defaultConfig);
          this.configForm.controls['profileStatus'].patchValue(data.configurationStatus);
          this.configForm.get('watchlistAndSanctions.timeFrame')?.patchValue(data.screeningLevels[0].selectedTimeFrame?.code);
          this.configForm.get('watchlistAndSanctions.customListId')?.patchValue(data.screeningLevels[0].customListId);
          if (data?.screeningLevels[2])
            this.configForm.get('adverseMedia.timeFrame')?.patchValue(data.screeningLevels[2].selectedTimeFrame?.code);
        }
      });
  }

  setSelectedScreeningToDefault(configMetaData: ScreeningLevel[], selected: ScreeningLevel[]) {
    //configMetaData.push(...this.watch_sanc_meta_ob);
    //selected.push(...this.watch_sanc_ob);
    selected.forEach((screening, index) => {
      configMetaData[index].categories = screening?.categories;
      configMetaData[index].eventTypes = screening?.eventTypes;
      configMetaData[index].subProducts = screening?.subProducts;
    }); // end of Screening Level Loop
    this.screeningLevels = configMetaData;
  }

  handleScreeningLevels() {
    this.screeningLevels.map((level) => {
      switch (level?.code) {
        case '1':
          this.watchList_Sanc_Handle(level);
          break;
        case '2':
          this.politicalHandle(level);
          break;
        case '3':
          this.adverseMediaHandle(level);
          break;
        case '4':
          this.watchListsHandle(level);
          break;
        case '5':
          this.sanctionsHandle(level);
          break;
      }
    });
  }

  watchList_Sanc_Handle(ele: ScreeningLevelWatchlList) {
    let tempArray: multiSelectArray[] = [];
    let selectedArray: selectedOptions[] = [];
    tempArray = [];
    this.watchLists_sanc.tabStatus = true;
    this.watchLists_sanc.description = ele.description;
    this.watchLists_sanc.timeFrame = ele.timeFrame || [];
    this.watchLists_sanc.customListEnabled = ele.customListEnabled || false;
    this.watchLists_sanc.code = ele.code;
    this.watchLists_sanc.subProducts = ele.subProducts || [];
    // normalizing multiple options into one array adding a field of parent 
    ele.categories.map((category) => {
      let tempOptions = category.options.map((ele) => {
        if (ele?.defaultOption === true || ele?.selected === true) {
          selectedArray.push({
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          });
        }
        return {
          label: ele.description,
          value: {
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          },
          tooltip: ele.toolTipDescription
        };
      });
      // options to items for primeNG rendering 
      tempArray.push({ label: category.code, value: category.code, items: tempOptions })
    });
    this.watchLists_sanc.categories = tempArray;
    this.configForm.patchValue({
      watchlistAndSanctions: {
        categories: selectedArray,
        customListEnabled: ele.customListEnabled
      }
    });
    this.patchSubProducts('watchlistAndSanctions.subProducts', this.watchLists_sanc.subProducts);
  }

  watchListsHandle(ele: ScreeningLevelWatchlList) {
    let tempArray: multiSelectArray[] = [];
    let selectedArray: selectedOptions[] = [];
    tempArray = [];
    this.watchLists.tabStatus = true;
    this.watchLists.description = ele.description;
    this.watchLists.timeFrame = ele.timeFrame || [];
    this.watchLists.customListEnabled = ele.customListEnabled || false;
    this.watchLists.code = ele.code;
    this.watchLists.subProducts = ele.subProducts || [];
    // normalizing multiple options into one array adding a field of parent 
    ele.categories.map((category) => {
      let tempOptions = category.options.map((ele) => {
        if (ele?.defaultOption === true || ele?.selected === true) {
          selectedArray.push({
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          });
        }
        return {
          label: ele.description,
          value: {
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          },
          tooltip: ele.toolTipDescription
        };
      });
      // options to items for primeNG rendering 
      tempArray.push({ label: category.code, value: category.code, items: tempOptions })
    });
    this.watchLists.categories = tempArray;
    this.configForm.patchValue({
      watchlists: {
        categories: selectedArray,
        customListEnabled: ele.customListEnabled
      }
    });
    this.patchSubProducts('watchlists.subProducts', this.watchLists_sanc.subProducts);
  }

  sanctionsHandle(ele: ScreeningLevelWatchlList) {
    let tempArray: multiSelectArray[] = [];
    let selectedArray: selectedOptions[] = [];
    tempArray = [];
    this.sanctions.tabStatus = true;
    this.sanctions.description = ele.description;
    this.sanctions.timeFrame = ele.timeFrame || [];
    this.sanctions.customListEnabled = ele.customListEnabled || false;
    this.sanctions.code = ele.code;
    this.sanctions.subProducts = ele.subProducts || [];
    // normalizing multiple options into one array adding a field of parent 
    ele.categories.map((category) => {
      let tempOptions = category.options.map((ele) => {
        if (ele?.defaultOption === true || ele?.selected === true) {
          selectedArray.push({
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          });
        }
        return {
          label: ele.description,
          value: {
            code: '' + ele.riskCategoryId,
            parentCode: category.code
          },
          tooltip: ele.toolTipDescription
        };
      });
      // options to items for primeNG rendering 
      tempArray.push({ label: category.code, value: category.code, items: tempOptions })
    });
    this.sanctions.categories = tempArray;
    this.configForm.patchValue({
      sanctions: {
        categories: selectedArray,
        customListEnabled: ele.customListEnabled
      }
    });
    this.patchSubProducts('sanctions.subProducts', this.watchLists_sanc.subProducts);
  }

  politicalHandle(ele: ScreeningLevel) {
    let tempArray: multiSelectArray[] = [];
    let selectedCategoryArray: string[] = [];
    this.political.tabStatus = true;
    this.political.description = ele.description;
    this.political.code = ele.code;
    this.political.subProducts = ele.subProducts || [];
    // normalizing multiple options into one array adding a field of parent 
    ele.categories.map((category) => {
      if (category?.defaultCategory === true || category?.selected === true) {
        selectedCategoryArray.push(category.code);
      }
      tempArray.push({ label: category.description, value: category.code, items: category.options })
    });
    this.political.categories = tempArray;
    this.configForm.patchValue({
      politicalAffiliations: {
        categories: selectedCategoryArray,
        customListEnabled: ele.customListEnabled
      }
    });
    this.patchSubProducts('politicalAffiliations.subProducts', this.political.subProducts);
  }

  adverseMediaHandle(ele: ScreeningLevel) {
    let tempArray: multiSelectArray[] = [];
    let selectedArray: selectedOptions[] = [];
    this.adverseMedia.tabStatus = true;
    this.adverseMedia.description = ele.description;
    this.adverseMedia.timeFrame = ele.timeFrame || [];
    this.adverseMedia.code = ele.code;
    this.adverseMedia.subProducts = ele.subProducts || [];
    // normalizing multiple options into one array adding a field of parent 
    ele.categories.map((category) => {
      let tempOptions = category.options.map((ele) => {
        if (ele?.defaultOption === true || ele?.selected === true) {
          selectedArray.push({
            code: ele.code,
            parentCode: category.code
          });
        }
        return {
          label: ele.description,
          value: {
            code: ele.code,
            parentCode: category.code
          },
          tooltip: ele.toolTipDescription
        };
      });
      tempArray.push({ label: category.description, value: category.code, items: tempOptions })
    });
    this.adverseMedia.categories = tempArray;
    this.configForm.patchValue({
      adverseMedia: {
        categories: selectedArray
      }
    });
    tempArray = [];
    selectedArray = [];
    ele.eventTypes?.map((event) => {
      let tempOptions = event.options.map((ele) => {
        if (ele?.defaultOption === true || ele?.selected === true) {
          selectedArray.push({
            code: ele.code,
            parentCode: event.code
          });
        }
        return {
          label: ele.description,
          value: {
            code: ele.code,
            parentCode: event.code
          },
          tooltip: ele.toolTipDescription
        };
      });
      tempArray.push({ label: event.code, value: event.code, items: tempOptions })
    });
    this.adverseMedia.eventTypes = tempArray;
    this.configForm.patchValue({
      adverseMedia: {
        eventTypes: selectedArray,
        customListEnabled: ele.customListEnabled
      }
    });
  }

  submitForm() {
    if (this.configForm.invalid) {
      for (const control of Object.keys(this.configForm.controls)) {
        this.configForm.controls[control].markAsTouched();
      }
      return;
    }
    let formData = this.configForm.value;
    this.request = {
      profileName: formData.profileName,
      descriptionNote: formData.descriptionNote,
      monitoringEnabled: formData.monitoringEnabled,
      defaultConfig: formData.defaultConfig,
      profileStatus: formData.profileStatus,
      screeningLevels: [],
    }
    if (this.watchLists_sanc?.tabStatus) {
      this.requestWatchList_sanc();
    }
    if (this.watchLists?.tabStatus) {
      this.requestWatchLists();
    }
    if (this.sanctions?.tabStatus) {
      this.requestSanctions();
    }
    if (this.political?.tabStatus) {
      this.requestPolitical();
    }
    if (this.adverseMedia?.tabStatus) {
      this.requestAdverseMedia();
    }
    this.createConfig(this.request, this.accountId);
  }

  requestWatchList_sanc() {
    let tmpArray = this.configForm.value.watchlistAndSanctions.categories;
    let watchListSancCategory = this.groupBy(tmpArray, 'parentCode');
    let flatCategoryList = this.prepareEventObj(watchListSancCategory);
    let watchlistScreenObj: ScreeningLevelRequest = {
      screeningLevel: this.watchLists_sanc.code,
      timeFrame: this.configForm.value.watchlistAndSanctions.timeFrame,
      isCustomListEnabled: this.configForm.value.watchlistAndSanctions.customListEnabled,
      customListId: this.configForm.value.watchlistAndSanctions.customListId,
      categories: flatCategoryList,
      selectedSubProducts: this.configForm.value.watchlistAndSanctions.subProducts,
      pressRelease: flatCategoryList.some(category => category['code'] === 'PRESS RELEASES')
    }
    this.request.screeningLevels.push(watchlistScreenObj);
  }

  requestWatchLists() {
    let tmpArray = this.configForm.value.watchlists.categories;
    let watchListCategory = this.groupBy(tmpArray, 'parentCode');
    let flatCategoryList = this.prepareEventObj(watchListCategory);
    let watchlistsObj: ScreeningLevelRequest = {
      screeningLevel: this.watchLists.code,
      timeFrame: this.configForm.value.watchlists.timeFrame,
      isCustomListEnabled: this.configForm.value.watchlists.customListEnabled,
      customListId: this.configForm.value.watchlists.customListId,
      categories: flatCategoryList,
      selectedSubProducts: this.configForm.value.watchlists.subProducts,
      pressRelease: flatCategoryList.some(category => category['code'] === 'PRESS RELEASES')
    }
    this.request.screeningLevels.push(watchlistsObj);
  }

  requestSanctions() {
    let tmpArray = this.configForm.value.sanctions.categories;
    let sanctionCategory = this.groupBy(tmpArray, 'parentCode');
    let flatCategoryList = this.prepareEventObj(sanctionCategory);
    let sanctionsObj: ScreeningLevelRequest = {
      screeningLevel: this.sanctions.code,
      timeFrame: this.configForm.value.sanctions.timeFrame,
      isCustomListEnabled: this.configForm.value.sanctions.customListEnabled,
      customListId: this.configForm.value.sanctions.customListId,
      categories: flatCategoryList,
      selectedSubProducts: this.configForm.value.sanctions.subProducts
    }
    this.request.screeningLevels.push(sanctionsObj);
  }

  requestPolitical() {
    let tmpArray = this.configForm.value.politicalAffiliations.categories;
    let politicalObj = {
      screeningLevel: this.political.code,
      categories: [{
        selectedOptions: tmpArray,
      }],
      selectedSubProducts: this.configForm.value.politicalAffiliations.subProducts,
      isCustomListEnabled: this.configForm.value.politicalAffiliations.customListEnabled,
    }
    this.request.screeningLevels.push(politicalObj);
  }

  requestAdverseMedia() {
    let tmpArray = this.configForm.value.adverseMedia.categories;
    let tmpArrayEvent = this.configForm.value.adverseMedia.eventTypes;
    let adverseMediaCategory = this.groupBy(tmpArray, 'parentCode');
    let adverseMediaEvent = this.groupBy(tmpArrayEvent, 'parentCode');
    let adverseMediaObj = {
      screeningLevel: this.adverseMedia.code,
      timeFrame: this.configForm.value.adverseMedia.timeFrame,
      categories: this.prepareEventObj(adverseMediaCategory),
      eventTypes: this.prepareEventObj(adverseMediaEvent),
      selectedSubProducts: this.configForm.value.adverseMedia.subProducts,
      isCustomListEnabled: this.configForm.value.adverseMedia.customListEnabled,
    }
    this.request.screeningLevels.push(adverseMediaObj);
  }

  createConfig(body: ConfigurationRequest, accountId: number) {
    if (!this.isUpdating) {
      this.configService.createConfiguration(body, accountId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.message });
          this.router.navigate(['/config/configuration/account', this.accountId, 'config', result?.responseData?.configurationId, 'view']);
        });
    } else {
      this.configService.updateConfiguration(body, accountId, this.configId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.message });
          this.router.navigate(['/config/configuration/account', this.accountId, 'config', result?.responseData?.configurationId, 'view']);
        });
    }
  }

  groupBy(objectArray: any[], key: string) {
    return objectArray.reduce((prevObj, currentObj) => {
      (prevObj[currentObj[key]] = prevObj[currentObj[key]] || [])
        .push(currentObj);
      return prevObj;
    }, {});
  }

  prepareEventObj(category: { [x: string]: { code: string; }[]; }) {
    let tempObj = [];
    for (let name in category) {
      tempObj.push({
        code: name,
        selectedOptions: category[name]
          .map((e: { code: string; }) => e.code)
      });
    }
    return [...tempObj];
  }

  onCheckChange(event: any, control: string) {
    const formArray: FormArray = this.configForm.get(control) as FormArray;
    // Selected
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    // unselected 
    else {
      // find the unselected element
      let i: number = 0;
      formArray.controls.forEach((ctrl) => {
        if (ctrl.value === event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  patchSubProducts(ctrl: string, options: Option[]) {
    const formArray: FormArray = this.configForm.get(ctrl) as FormArray;
    options.map(option => {
      if (option?.defaultOption || option?.selected) {
        formArray.push(new FormControl(option.code));
      }
    })
  }

  setSelection(event: any, items: any, formCtrl: string) {
    let contorl = this.configForm.get(formCtrl);
    let preSelected = contorl?.value;
    let selected = items.map((i: { value: any; }) => i.value)
    if (formCtrl === 'watchlistAndSanctions.categories')
      preSelected = preSelected.filter((el: any) => !selected.find((rm: any) => (rm.riskCategoryId === el.riskCategoryId && el.parentCode === rm.parentCode)));
    else
      preSelected = preSelected.filter((el: any) => !selected.find((rm: any) => (rm.code === el.code && el.parentCode === rm.parentCode)));

    if (event.target.checked) {
      preSelected.push(...selected);
      contorl?.patchValue(preSelected);
    } else {
      contorl?.patchValue(preSelected);
    }
  }

  selectedGroupLength(grpLabel: string, formCtrl: string, itemLength: number) {
    let contorl = this.configForm.get(formCtrl);
    let preSelected: any[] = contorl?.value;
    let selectedLength = 0;
    preSelected.map(item => {
      if (item.parentCode === grpLabel)
        selectedLength++;
    })
    return (itemLength === selectedLength);
  }

  public changed(evt: any) {    
    this.configService.enable_WLS_SPLIT.next(evt.target.checked);
    }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
